import React, { Component } from "react"

import SEO from "../components/seo"
import SiteContext from "../context/SiteContext"
import Layout from "../components/layout"
import Sidebar from "../components/Sidebar"
import SectorSelect from "../components/SectorSelect"
import AreaSelect from "../components/AreaSelect"
import ApplicationSelect from "../components/ApplicationSelect"
import TopAttributeSelect from "../components/TopAttributeSelect"
import ProductList from "../components/ProductList"
import headerArrows from '../images/header-arrows.png'
import lowDustImgFlow from '../images/low_dust_masterflow.jpg'
import topIntroImage from '../images/mastertop-image.jpg'

import Airtable from 'airtable'
import { ThreeSixtySharp } from "@material-ui/icons"

const base = new Airtable({ apiKey: 'pat6en3hQHeHv0y9t.28a0168305fe037a3d3264a5a5fef34de50ce2fab5a6fb009bdf6cd3c567df40' }).base('appetvKIzMHDQnENv');

class WelcomeMasterTop extends Component {

    constructor(props) {
        super(props)
        this.state = {
            activeStep: 'sector',
            showSectorSelect: false,
            showApplicationSelect: false,
            showAreaSelect: false,
            showAttributeSelect: false,
            showProductPage: false,
            subSectors: [],
            relatedSubSectors: [],
            products: [],
            industries: [],
            areas: [],
            relatedAreas: [],
            allApplications: [],
            applications: [],
            attributes: [],
            attributeGroups: [],
            subIndustries: [],
            benefits: [],
            selectedSector: '',
            selectedApplications: [],
            selectedArea: '',
            selectedAttributes: [],
            validAttributes: [],
            availableProducts: [],
            sectorNextEnabled: 'disabled',
            subSectorNextEnabled: 'disabled',
            applicationNextEnabled: 'disabled',
            areaNextEnabled: 'disabled',
            attributeNextEnabled: 'disabled',
            atLimit: false,
            site: 'mastertop', 
            siteTitle: 'Master Builders Solutions <br/> Resinous Flooring Selection Guide',
            introContent: 'Choosing the right floor is vital to the success of a facility. Master Builders Solutions provides high-quality, durable solutions that meet the most demanding needs. Use the Performance Flooring product selector tool to help find a solution for your project.',
            stepOneText: 'Step 1: Choose the Industry', 
            stepTwoText: 'Step 2: Choose the Subsector',
            stepThreeText: 'Step 3: Choose the Area',
            stepFourText: 'Step 4: Choose the Attributes',
            sidebarGroupOneTitle: 'Industry',
            sidebarGroupTwoTitle: 'Subsector',
            sidebarGroupThreeTitle: 'Areas',
            sidebarGroupFourTitle: 'Attributes',
            lowDustBoxTitle: 'Interested in our Low Dust Technology?',
            lowDustBoxContent: '',
            lowDustBoxLink: 'https://mbcc.sika.com/en-us/products/grouts',
            attributeSelectTitle: 'Choose up to 5 product attributes',
            showModal:false,
            modalId:"",
            contactRepPDFs:[],
        }
    }

    async componentDidMount() {
        // Get all product data from airtable

        try {
            base('Industries').select({ view: 'All Industries' })
                .eachPage(
                    (records, fetchNextPage) => {

                        const industries = records.sort(function (a, b) {
                            if (a.fields.Name > b.fields.Name) return 1;
                            if (a.fields.Name < b.fields.Name) return -1;
                            return 0;
                        });
                        // console.log("industries on fetch", industries)
                        this.setState({
                            industries,
                        });
                        fetchNextPage(); 
                    }
                );

            base('Areas').select({ view: 'All Areas' })
                .eachPage(
                    (records, fetchNextPage) => {

                        const areas = records.sort(function (a, b) {
                            if (a.fields.Name > b.fields.Name) return 1;
                            if (a.fields.Name < b.fields.Name) return -1;
                            return 0;
                        });
                        // console.log("areas on fetch", areas)
                        this.setState({
                            areas,
                        });
                        fetchNextPage();
                    }
                );

            base('Sub Industries').select({ view: 'All Sub Industries' })
                .eachPage(
                    (records, fetchNextPage) => {

                        const subSectors = records.sort(function (a, b) {
                            if (a.fields.Name > b.fields.Name) return 1;
                            if (a.fields.Name < b.fields.Name) return -1;
                            return 0;
                        });

                        // console.log("sub industries on fetch", subSectors)
                        this.setState({
                            subSectors
                        });
                        fetchNextPage();
                    }
                );

            base('Attributes').select({ view: 'All Attributes' })
                .eachPage(
                    (records, fetchNextPage) => {

                        const attributes = records.sort(function (a, b) {
                            if (a.fields.Priority > b.fields.Priority) return 1;
                            if (a.fields.Priority < b.fields.Priority) return -1;
                            return 0;
                        });

                        let validAttributes = attributes.map(attribute => attribute.fields.Name)

                        // console.log("attributes on fetch", attributes)
                        this.setState({
                            attributes,
                            validAttributes
                        });
                        fetchNextPage();
                    }
                );

            base('Attribute Groups').select({ view: 'All Attribute Groups' })
                .eachPage(
                    (records, fetchNextPage) => {

                        const attributeGroups = records.sort(function (a, b) {
                            if (a.fields.Priority > b.fields.Priority) return 1;
                            if (a.fields.Priority < b.fields.Priority) return -1;
                            return 0;
                        });

                        // console.log("attribute groups on fetch", attributeGroups)
                        this.setState({
                            attributeGroups
                        });
                        fetchNextPage();
                    }
                );

            base('Contact Rep PDF').select({ view: 'Grid view' })
                .eachPage(
                    (records, fetchNextPage) => {
                        console.log('reps', records)
                        this.setState({
                            contactRepPDFs : records
                        });
                        fetchNextPage();
                    }
                );

            base('Products').select({ view: 'All Products' })
                .eachPage(
                    (records, fetchNextPage) => {
                        const products = records.sort(function (a, b) {
                            if (a.fields.Name > b.fields.Name) return 1;
                            if (a.fields.Name < b.fields.Name) return -1;
                            return 0;
                        });

                        // console.log("products on fetch", products)
                        this.setState({
                            products,
                            showSectorSelect: true
                        });
                        fetchNextPage();
                    }
                );
        } catch (err) {
            console.error(err)
        }

        // Get list of sectors from product data

    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };

    addSector = sector => {
        const relatedSubSectors = this.state.subSectors.filter(subSector => {
            return typeof subSector.fields['Name (from Industries)'] !== 'undefined' && subSector.fields['Name (from Industries)'].includes(sector)
        })

        this.setState({ 
            selectedSector: sector,
            sectorNextEnabled: '',
            relatedSubSectors,
        })
    }

    addApplications = application => {
        let newApplications = []
        if (this.state.selectedApplications.some(item => item === application)) {
            newApplications = this.state.selectedApplications.filter(function (el) { return el !== application; });
        } else {
            newApplications = [...this.state.selectedApplications, application]
        }

        const relatedAreas = newApplications.map(application => {
            return this.state.areas.filter(area => {
                return typeof area.fields['Name (from Sub Industries)'] !== 'undefined' && area.fields['Name (from Sub Industries)'].includes(application)
            })
        })

        const uniqueAreas = Array.from(new Set(relatedAreas.flat().map(a => a.id)))
            .map(id => {
                return this.state.areas.find(a => a.id === id)
            })

        this.setState({ 
            selectedApplications: newApplications,
            applicationNextEnabled: newApplications.length > 0 ? '' : 'disabled',
            relatedAreas: uniqueAreas
        })
    }

    addAreas = area => {
        this.setState({
            selectedArea: area,
            areaNextEnabled: '',
        })
    }

    isTrue = (arr, arr2) => {
        return arr.every(i => arr2.includes(i));
    }

    setValidAttributes = (attributeObj, newAttributes) => {
        this.setState({
            validAttributes: newAttributes
        }, () => {
            let productsToCheck = this.state.availableProducts.length ? this.state.availableProducts : this.state.products
    
            let validAttributes = productsToCheck.map(system => {
                return system.fields['Lookup for Attributes']
            })
    
            const mergedValidAttributes = [].concat.apply([], validAttributes);
            let uniqueValidAttributes = [...new Set(mergedValidAttributes)];
    
            this.setState({ validAttributes: uniqueValidAttributes.length ? uniqueValidAttributes : this.state.attributes.map(attr => attr.fields.Name), })
        })

    }

    addAttributes = attributeObj => {
        const attribute = {
            name: attributeObj.fields.Name,
            id: attributeObj.id
        }

        let newAttributes = []
        let atLimit = this.state.atLimit
        if (!atLimit) {
            if (this.state.selectedAttributes.some(item => item.id === attribute.id)) {
                newAttributes = this.state.selectedAttributes.filter(function (el) { return el.id !== attribute.id; });
            } else {
                newAttributes = [...this.state.selectedAttributes, attribute]
            }

            this.setState({
                selectedAttributes: newAttributes,
                attributeNextEnabled: newAttributes.length > 0 ? '' : 'disabled',
                atLimit
            }, () => {
                const availableProducts = []
                if (this.state.selectedAttributes.length) {
                    this.state.products.forEach(product => {
                        const productAttrs = product.fields['Lookup for Attributes']
                        const productSectors = product.fields['Lookup for Sectors']
                        // From https://josephkhan.me/check-if-an-array-is-a-subset-of-another-array-in-javascript/
                        const attrsObj = {}
                        if (typeof productAttrs !== 'undefined') {
                            productAttrs.forEach((el, index) => {
                                attrsObj[el] = index;
                            });
    
                            var productHasAttributes = this.state.selectedAttributes.every((el) => {
                                return attrsObj[el.name] !== undefined; //because 0 is falsy
                            });
    
                            const productApplications = product.fields['Lookup for Applications']
    
                            if (productApplications) {
                                const appsObj = {}
                                productApplications.forEach((el, index) => {
                                    appsObj[el] = index;
                                });
    
                                var productHasApplications = this.state.selectedApplications.every((el) => {
                                    return appsObj[el] !== undefined; //because 0 is falsy
                                });
                            }
    
                            const productHasSector = typeof productSectors !== 'undefined' && productSectors.includes(this.state.selectedSector)

                            if (productHasSector && productHasAttributes && productHasApplications) {
                                availableProducts.push(product)
                            }
                            this.setState({ availableProducts }, () => {
                                this.setValidAttributes(attributeObj, this.state.selectedAttributes)
                            })
                        }
                    })
                } else {
                    this.setState({ availableProducts: [] }, () => {
                        this.setValidAttributes(attributeObj, this.state.selectedAttributes)
                    })
                }
            })
        } else {
            if (this.state.selectedAttributes.some(item => item.id === attribute.id)) {
                newAttributes = this.state.selectedAttributes.filter(function (el) { return el.id !== attribute.id; });

                this.setState({
                    selectedAttributes: newAttributes,
                    attributeNextEnabled: '',
                    atLimit: false
                })
            }
        }
    }

    handleNextClick = step => {
        switch (step) {
            case 'application':
                this.setState({
                    activeStep: 'application',
                    showSectorSelect: false,
                    showApplicationSelect: true,
                    showAreaSelect: false,
                    showAttributeSelect: false,
                    showProductPage: false,
                })
                this.scrollToTop()
                break;
            case 'area':
                this.setState({
                    activeStep: 'area',
                    showSectorSelect: false,
                    showApplicationSelect: false,
                    showAreaSelect: true,
                    showAttributeSelect: false,
                    showProductPage: false,
                })
                this.scrollToTop()
                break;
            case 'attribute':
                this.setState({
                    activeStep: 'attribute',
                    showSectorSelect: false,
                    showApplicationSelect: false,
                    showAreaSelect: false,
                    showAttributeSelect: true,
                    showProductPage: false,
                })
                this.scrollToTop()
                break;
            case 'product':
                this.setState({
                    activeStep: 'product',
                    showSectorSelect: false,
                    showApplicationSelect: false,
                    showAreaSelect: false,
                    showAttributeSelect: false,
                    showProductPage: true,
                })
                this.scrollToTop()
                break;
            default:
                break;
        }
    }

    handleBackClick = step => {
        switch (step) {
            case 'sector':
                console.log("back to sector")
                this.setState({
                    activeStep: 'sector',
                    showSectorSelect: true,
                    showApplicationSelect: false,
                    showAreaSelect: false,
                    showAttributeSelect: false,
                    showProductPage: false,
                    applicationNextEnabled: 'disabled',
                    selectedApplications: [],
                    selectedArea: '',
                    selectedAttributes: [],
                    availableProducts: [],
                    validAttributes: this.state.attributes.map(attribute => attribute.fields.Name)
                })
                this.scrollToTop()
                break;
            case 'application':
                this.setState({
                    activeStep: 'application',
                    showSectorSelect: false,
                    showApplicationSelect: true,
                    showAreaSelect: false,
                    showAttributeSelect: false,
                    showProductPage: false,
                    areaNextEnabled: 'disabled',
                    selectedAttributes: [],
                    selectedArea: '',
                    availableProducts: [],
                    validAttributes: this.state.attributes.map(attribute => attribute.fields.Name)
                })
                this.scrollToTop()
                break;
            case 'area':
                this.setState({
                    activeStep: 'area',
                    showSectorSelect: false,
                    showApplicationSelect: false,
                    showAreaSelect: true,
                    showAttributeSelect: false,
                    showProductPage: false,
                    attributeNextEnabled: 'disabled',
                    selectedAttributes: [],
                    availableProducts: [],
                    validAttributes: this.state.attributes.map(attribute => attribute.fields.Name)
                })
                this.scrollToTop()
                break;
            case 'attribute':
                this.setState({
                    activeStep: 'attribute',
                    showSectorSelect: false,
                    showApplicationSelect: false,
                    showAreaSelect: false,
                    showAttributeSelect: true,
                    showProductPage: false,
                    validAttributes: this.state.attributes.map(attribute => attribute.fields.Name)
                })
                this.scrollToTop()
                break;
            default:
                break;
        }
    }

    handleModal = (modalId) => {
        this.setState(prevState => ({
            showModal: !prevState.showModal,
            modalId : modalId
        }));
    }

    render() {
        return (
            <SiteContext.Consumer>
                {siteContext => (
                    <div className="site-mastertop">
                        <Layout>
                            <SEO title="MasterTop Flooring Selector Tool" description="" />
                            <div className="wrapper wrapper--tool">
                                <Sidebar 
                                    selectedSector={this.state.selectedSector} 
                                    selectedApplications={this.state.selectedApplications} 
                                    selectedArea={this.state.selectedArea}
                                    selectedAttributes={this.state.selectedAttributes} 
                                    availableProducts={this.state.availableProducts}
                                    activeStep={this.state.activeStep}
                                    site={this.state.site}
                                    sidebarGroupOneTitle={this.state.sidebarGroupOneTitle}
                                    sidebarGroupTwoTitle={this.state.sidebarGroupTwoTitle}
                                    sidebarGroupThreeTitle={this.state.sidebarGroupThreeTitle}
                                    sidebarGroupFourTitle={this.state.sidebarGroupFourTitle}
                                />
                                <div className="content-wrapper">
                                    
                                    {this.state.showSectorSelect 
                                        ? <SectorSelect 
                                            sectors={this.state.industries} 
                                            addSector={this.addSector} 
                                            selectedSector={this.state.selectedSector} 
                                            handleNextClick={this.handleNextClick} 
                                            handleBackClick={this.handleBackClick}
                                            sectorNextEnabled={this.state.sectorNextEnabled}
                                            availableProducts={this.state.availableProducts}
                                            products={this.state.products}
                                            site={this.state.site} 
                                            siteTitle={this.state.siteTitle}
                                            introContent={this.state.introContent}
                                            introImage={topIntroImage}
                                            stepOneText={this.state.stepOneText}
                                            lowDustBoxTitle={this.state.lowDustBoxTitle}
                                            lowDustBoxContent={this.state.lowDustBoxContent}
                                            lowDustBoxImage={lowDustImgFlow}
                                            lowDustBoxLink={this.state.lowDustBoxLink}
                                            next="application"
                                        /> 
                                        : null}

                                    {/* Applications are sub sectors */}
                                    {this.state.showApplicationSelect 
                                        ? <ApplicationSelect 
                                            applications={this.state.relatedSubSectors}
                                            addApplications={this.addApplications} 
                                            selectedSector={this.state.selectedSector} 
                                            selectedApplications={this.state.selectedApplications} 
                                            handleNextClick={this.handleNextClick} 
                                            handleBackClick={this.handleBackClick}
                                            stepTwoText={this.state.stepTwoText}
                                            applicationNextEnabled={this.state.applicationNextEnabled}
                                            availableProducts={this.state.availableProducts} 
                                            products={this.state.products}
                                            site={this.state.site}
                                            next="area"
                                            previous="sector"
                                        /> 
                                        : null}

                                    {this.state.showAreaSelect
                                        ? <AreaSelect
                                            areas={this.state.relatedAreas}
                                            addAreas={this.addAreas}
                                            selectedSector={this.state.selectedSector}
                                            selectedArea={this.state.selectedArea}
                                            selectedApplications={this.state.selectedApplications}
                                            handleNextClick={this.handleNextClick}
                                            handleBackClick={this.handleBackClick}
                                            stepThreeText={this.state.stepThreeText}
                                            areaNextEnabled={this.state.areaNextEnabled}
                                            availableProducts={this.state.availableProducts}
                                            products={this.state.products}
                                            site={this.state.site}
                                            next="attribute"
                                            previous="application"
                                        />
                                        : null}

                                    {this.state.showAttributeSelect 
                                        ? <TopAttributeSelect 
                                            attributes={this.state.attributes}
                                            attributeGroups={this.state.attributeGroups}
                                            addAttributes={this.addAttributes} 
                                            selectedSector={this.state.selectedSector}
                                            selectedApplications={this.state.selectedApplications} 
                                            selectedArea={this.state.selectedArea}
                                            selectedAttributes={this.state.selectedAttributes} 
                                            validAttributes={this.state.validAttributes}
                                            handleNextClick={this.handleNextClick} 
                                            handleBackClick={this.handleBackClick}
                                            stepFourText={this.state.stepFourText}
                                            attributeNextEnabled={this.state.attributeNextEnabled}
                                            availableProducts={this.state.availableProducts} 
                                            atLimit={this.state.atLimit}
                                            site={this.state.site}
                                            products={this.state.products}
                                            attributeSelectTitle={this.state.attributeSelectTitle}
                                            next="product"
                                            previous="area" 
                                            site="mastertop"
                                        /> 
                                        : null}

                                    {this.state.showProductPage
                                        ? <ProductList
                                            availableProducts={this.state.availableProducts}
                                            handleNextClick={this.handleNextClick}
                                            handleBackClick={this.handleBackClick}
                                            selectedSector={this.state.selectedSector}
                                            selectedApplications={this.state.selectedApplications} 
                                            selectedAttributes={this.state.selectedAttributes}
                                            selectedArea={this.state.selectedArea}
                                            products={this.state.products}
                                            site={this.state.site}
                                            previous="attribute"
                                            handleModal={this.handleModal}
                                            showModal={this.state.showModal}
                                            modalId={this.state.modalId}
                                        />
                                        : null}

                                </div>
                            </div>
                        </Layout>
                        <div className={`modal-bg modal-bg-show--${this.state.showModal}`} onClick={() => this.handleModal()}></div>    
                        <div className={`mastertop-modal mastertop-modal--outside ${this.state.modalId === 'contactRep' && this.state.showModal ? 'modal-show--true' : 'modal-show--false'}`}>
                            <h2>Contact a Rep</h2>
                            <svg className="product-card__close" onClick={() => this.handleModal()} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 0C8.08215 0 5.28469 1.15867 3.22197 3.22197C1.15855 5.28448 0 8.08215 0 11C0 13.9178 1.15867 16.7153 3.22197 18.778C5.28448 20.8414 8.08215 22 11 22C13.9178 22 16.7153 20.8413 18.778 18.778C20.8414 16.7155 22 13.9178 22 11C21.9963 8.08309 20.8368 5.28727 18.7743 3.22573C16.7127 1.16322 13.9169 0.00375467 11 0V0ZM11 20.5333C8.47194 20.5333 6.04642 19.5287 4.25873 17.7413C2.47123 15.9538 1.46667 13.5283 1.46667 11C1.46667 8.4717 2.47132 6.04642 4.25873 4.25873C6.04623 2.47123 8.4717 1.46667 11 1.46667C13.5283 1.46667 15.9536 2.47132 17.7413 4.25873C19.5288 6.04623 20.5333 8.4717 20.5333 11C20.5306 13.5274 19.525 15.951 17.7375 17.7375C15.9509 19.525 13.5274 20.5305 11 20.5333Z" fill="#F59100" /><path d="M15.1484 6.85201C15.0109 6.71451 14.8239 6.6366 14.6296 6.6366C14.4353 6.6366 14.2492 6.71451 14.1117 6.85201L10.9995 9.96322L7.88738 6.85201C7.60045 6.57335 7.14304 6.57792 6.8598 6.86026C6.57655 7.14351 6.57287 7.60092 6.85063 7.88876L9.96278 11L6.85157 14.1112H6.85065C6.70948 14.2478 6.62882 14.4357 6.62698 14.6328C6.62515 14.8289 6.70307 15.0187 6.84148 15.1571C6.98082 15.2964 7.16964 15.3734 7.36674 15.3725C7.56382 15.3707 7.75082 15.29 7.88742 15.1479L10.9996 12.0367L14.1117 15.1479C14.2483 15.29 14.4353 15.3707 14.6324 15.3725C14.8295 15.3734 15.0183 15.2964 15.1577 15.1571C15.2961 15.0187 15.374 14.829 15.3722 14.6328C15.3703 14.4357 15.2897 14.2478 15.1485 14.1112L12.0363 11L15.1475 7.88876H15.1485C15.286 7.75126 15.363 7.56426 15.363 7.36994C15.363 7.17561 15.286 6.98952 15.1485 6.85203L15.1484 6.85201Z" fill="#F59100" /></svg>
                            {
                                this.state.contactRepPDFs.map( pdf => {
                                    return (
                                        <a className="product-card__button" key={pdf.fields["Name"]} href={pdf.fields["URL"]} target="_blank" rel="noopener noreferrer">{pdf.fields["Name"]}</a>
                                    )
                                })  
                            }
                        </div>   
                    </div> 
                )}
            </SiteContext.Consumer>
        )
    }
}

export default WelcomeMasterTop