import React, { Component } from 'react'
import { navigate } from "@reach/router"
import BagCalculator from "../components/BagCalculator"
import arrowRight from '../images/arrow-right.svg'
import arrowLeft from '../images/arrow-left.svg'
import arrowRightGrey from '../images/arrow-right-grey.svg'

class AreaSelect extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    handleAreaClick = area => {
        this.props.addAreas(area)
    }

    handleSectorBreadcrumbClick = () => {
        this.props.handleBackClick('sector')
    }

    handleApplicationBreadcrumbClick = () => {
        this.props.handleBackClick('application')
    }

    render() {
        return (
            <div className="sector-select">
                {this.props.site !== 'masterseal' && this.props.site !== 'mastertop'
                    ? <BagCalculator availableProducts={this.props.availableProducts} products={this.props.products} />
                    : ""
                }
                <div className="sector-select__header">
                    <div>
                        <h2>{this.props.stepThreeText}</h2>
                        <div className="breadcrumbs">
                            <a onClick={this.handleSectorBreadcrumbClick}>{this.props.selectedSector}</a>
                            <span><img src={arrowRightGrey} alt="" /></span>
                            <a onClick={this.handleApplicationBreadcrumbClick}>{this.props.selectedApplications.join(', ')}</a>
                        </div>
                    </div>
                    <div className="sector-select__nav">
                        <div className="nav-buttons">
                            <span className="nav-button__prev" onClick={() => this.props.handleBackClick(this.props.previous)}>
                                <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.47809 -4.09395e-08L5.41467 0.936585L1.87321 4.44878L5.41467 8.06341L4.52199 9L0.936622 5.38537L3.69988e-05 4.44878L4.47809 -4.09395e-08Z" fill="#7C7C7C" /></svg>
                                Back
                            </span>
                            <span className={`nav-button__next ${this.props.areaNextEnabled}`} onClick={() => this.props.areaNextEnabled === '' ? this.props.handleNextClick(this.props.next) : null}>
                                Next
                                <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.936586 9L-4.09394e-08 8.06341L3.54146 4.55122L-3.52463e-07 0.936585L0.892683 -3.90204e-08L4.47805 3.61463L5.41464 4.55122L0.936586 9Z" fill="#FFF" /></svg>
                            </span>
                        </div>
                    </div>
                </div>
                {this.props.areas ?
                    this.props.areas.map((area) => {
                        return <span key={area.fields.id}
                        className={`btn-square orange area-select option-selector ${this.props.selectedArea === area.fields.Name ? 'active' : ''} ${this.props.site}`}
                        onClick={() => this.handleAreaClick(area.fields.Name)}>
                            { this.props.site === "mastertop" 
                                ?  <><p>{area.fields.Name}</p><div>{area.fields.Description}</div></> 
                                : area.fields.Name
                             }
               
                        </span>
                    })
                    : null}
                <div className="nav-buttons nav-buttons__bottom-set">
                    <span className="nav-button__prev" onClick={() => this.props.handleBackClick(this.props.previous)}>
                        <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.47809 -4.09395e-08L5.41467 0.936585L1.87321 4.44878L5.41467 8.06341L4.52199 9L0.936622 5.38537L3.69988e-05 4.44878L4.47809 -4.09395e-08Z" fill="#7C7C7C" /></svg>
                        Back
                    </span>
                    <span className={`nav-button__next ${this.props.areaNextEnabled}`} onClick={() => this.props.areaNextEnabled === '' ? this.props.handleNextClick(this.props.next) : null}>
                        Next
                        <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.936586 9L-4.09394e-08 8.06341L3.54146 4.55122L-3.52463e-07 0.936585L0.892683 -3.90204e-08L4.47805 3.61463L5.41464 4.55122L0.936586 9Z" fill="#FFF" /></svg>
                    </span>
                </div>
            </div>
        )
    }
}

export default AreaSelect

